import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { useRoute } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    const $route = useRoute();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_unref($route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _unref($route).path
        })) : _createCommentVNode("", true)], 1024))]),
        _: 1
      }), _createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [!_unref($route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _unref($route).path
        })) : _createCommentVNode("", true)]),
        _: 1
      })], 64);
    };
  }
};