import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home"
};
import { ref } from 'vue';
// import config from "../config/index";
// console.log("1111", process.env.NODE_ENV,config.baseUrl);

// console.log(this.$router);

export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createTextVNode(" 首页 "), _createVNode(_component_router_link, {
        to: "/about"
      }, {
        default: _withCtx(() => [_createTextVNode("About")]),
        _: 1
      })]);
    };
  }
};